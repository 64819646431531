<!-- 处理反馈内容 -->
<template>
  <div class="">
    <ax-form ref="form1" :formBuilder="formBuilder1">
      <!-- 处理时间 -->
      <div slot="processEvent">
        <a-date-picker
          :value="startValue"
          :disabled-date="disabledDate"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          format="YYYY-MM-DD HH:mm:ss"
          placeholder="请选择日期"
          @ok="ok"
          show-time
          v-decorator="[
            'processEvent',
            {
              initialValue: moment(),
              rules: [{ required: true, message: '请选择处理时间' }],
            },
          ]"
        />
      </div>
      <!-- 处理后照片 -->
      <div slot="processPhoto">
        <imageUpload ref="img1" :businessId="businessId"></imageUpload>
      </div>
    </ax-form>
    <div class="bottom">
      <time-line :steps="steps"></time-line>
    </div>
    <div class="btn-group">
      <!--  v-if="status1 === 0" -->
      <div style="display: flex; justify-content: flex-end">
        <ax-button @click="returnEvent" style="margin: 0 1%" type="#F95A5A">中止</ax-button>
        <ax-button @click="submit" type="#E6A23C">申请延期</ax-button>
        <ax-button @click="submit"  style="margin: 0 1%" type="#2A5CFF">处理</ax-button>
      </div>
    </div>
     <!-- 处理反馈-退回弹窗 -->
     <el-dialog
      title="处理反馈"
      :visible.sync="visible"
      width="40%"
      @click="closeDialog"
      append-to-body
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-input
        type="textarea"
        :rows="4"
        placeholder="(必填)请填写退回理由"
        v-model="textarea"
      >
      </el-input>
      <div style="display: flex; justify-content: flex-end; margin-top: 5%">
        <a-button style="margin-left: 20px; margin: 0 1%" @click="closeDialog">
          取消
        </a-button>
        <a-button type="primary" @click="onSubmit" :loading="saveType">
          确认
        </a-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import imageUpload from "../ax-image-modal copy/src/image-upload.vue";
import TimeLine from "./timeLine.vue";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const formList1 = [
  {
    label: "处理人姓名",
    type: "",
    model: "processorName",
    options: { disabled: true, placeholder: "处理人姓名", maxLength: 18 },
    col: { span: 24 },
    // rules: [{ required: true, message: "请选择派遣人员" }],
  },
  {
    label: "处理人电话",
    type: "",
    model: "processorPhone",
    options: { disabled: true, placeholder: "处理人电话", maxLength: 18 },
    col: { span: 24 },
    // rules: [{ required: true, message: "请选择派遣人员" }],
  },
  {
    label: "处理时间",
    type: "",
    model: "processEvent",
    options: { placeholder: "" },
    col: { span: 24 },
    // rules: [{ required: true, message: "请选择处理时间" }],
  },
  {
    label: "处理情况",
    type: "",
    model: "processStatus",
    options: { placeholder: "请输入", maxLength: 200 },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入处理情况" }],
  },

  {
    label: "其他说明",
    type: "textarea",
    model: "dispatchedInstructions",
    options: { placeholder: "请输入", minRows: 4, maxLength: 500 },
    col: { span: 24 },
  },
  {
    label: "处理后照片",
    type: "",
    model: "processPhoto",
    options: { placeholder: "请输入" },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入处理情况" }],
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { imageUpload, TimeLine },
  props: ["status1", "steps"],
  data() {
    //这里存放数据
    return {
      moment,
      businessId: "",
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 8 } }
      ),
      dispatchedPersonnelName: "",
      dispatchedPersonnelInfo: {},
      processEvent: null, //事件时间
      startValue: null, //开始时间
      visible: false, 
      textarea: "",
      saveType: true,
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapGetters("app", ["userInfo"]),
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    setDefaultInformation() {
      this.$refs.form1.setFieldsValue({
        processorName: this.userInfo.realname,
      });
      this.$refs.form1.setFieldsValue({ processorPhone: this.userInfo.phone });
    },
    // 禁用的日期
    disabledDate(startValue) {
      if (!startValue) {
        return false;
      }
      return startValue.valueOf() > new Date().valueOf();
    },
    // 时间选择器组件的确定按钮事件
    ok(e) {
      this.processEvent = this.formatDateTime(e._d);
    },
    // 格式化时间为 字符串格式
    formatDateTime(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const day = String(d.getDate()).padStart(2, "0");
      const hours = String(d.getHours()).padStart(2, "0");
      const minutes = String(d.getMinutes()).padStart(2, "0");
      const seconds = String(d.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    submit() {
      this.$refs.form1.form.validateFields(async (errors, value) => {
        if (errors) return;
        console.log(value);
        this.$emit("submit", {
          ...this.dispatchedPersonnelInfo,
          dispatchedInstructions: value.dispatchedInstructions,
        });
      });
    },
    returnEvent() {
      this.visible = true;
    },
    //关闭弹窗
    closeDialog() {
      this.visible = false;
    },
    //提交
    async onSubmit() {
      console.log(this.textarea);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.setDefaultInformation();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.ant-calendar-picker {
  width: 100%;
}
/deep/.ant-form {
  border: 1px solid #e2e8f1;
  padding: 2%;
  padding-bottom: 0;
}
.bottom {
  margin-top: 1%;
}
.btn-group {
  margin-top: 2%;
}
</style>